import { storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import { formatCurrency } from '../utils/currency';

export default class ControllerBalanceView {

  constructor(main) {
    this.LocalizationManager = window.OPWrapperService.localizations;
    let field = main.getChildByName('field');
    this.labelBalance = field.getChildByName('labelBalance');
    this.labelCurrency = field.getChildByName('labelCurrency');

    this.balance = storeObserver.addListenerAndGetValue(selectors.getBalance, this.onBalanceChanged);
    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.onCurrencyInfoChanged);

    this.labelBalance.text = this.LocalizationManager.getLocalizedText('game_balance', [formatCurrency(this.balance, this.currencyInfo.decimal)]);

    this.labelCurrency.text = this.currencyInfo.currency;
  }

  onBalanceChanged = (value) => {
    this.balance = value;
    this.labelBalance.text = this.LocalizationManager.getLocalizedText('game_balance', [formatCurrency(this.balance, this.currencyInfo.decimal)]);
  };

  onCurrencyInfoChanged = (value) => {
    this.currencyInfo = value;
    this.labelCurrency.text = value.currency;
    this.onBalanceChanged(this.balance);
  };
}
