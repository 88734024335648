export default {
  CANVAS_WIDTH_LANDSCAPE: 2080,
  CANVAS_HEIGHT_LANDSCAPE: 1170,

  CANVAS_WIDTH_PORTRAIT: 2080,
  CANVAS_HEIGHT_PORTRAIT: 1170,

  SAFE_WIDTH_LANDSCAPE: 460,
  SAFE_HEIGHT_LANDSCAPE: 1120,

  SAFE_WIDTH_PORTRAIT: 500,
  SAFE_HEIGHT_PORTRAIT: 940

}
