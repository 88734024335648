import { eGameState } from '../../redux/game/reducer';
import { requestNewGame, requestTakeGame } from '../../api/rest';
import { storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { store } from '../../index';
import { yellowStrokeGradient } from '../../fontStyles';
import { formatCurrency } from '../../utils/currency';

export default class ControllerButtonPlay {
  constructor(mainContainer) {
    this.LocalizationManager = window.OPWrapperService.localizations;
    this.button = mainContainer.getChildByName('field').getChildByName('buttonPlay');
    this.button.text.style = { ...this.button.text.style, ...yellowStrokeGradient };

    this.button.addListener('pointertap', this.onButtonClicked);

    this.longLabel = this.button.getChildByName('labelLong');
    this.longLabel.style = {
      ...this.longLabel.style,
      ...yellowStrokeGradient,
      letterSpacing: 3,
      fontSize: 50
    };
    this.longLabel.y = this.longLabel.displayData.y + 30;
    this.longLabel.visible = false;

    this.gameState = storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.onGameStateChanged(this.gameState);
    storeObserver.addListener(selectors.getWinAmount, this.onWinAmountChanged);
  }

  // Events

  onButtonClicked = async () => {
    //show preloader
    let state = store.getState();
    let betAmount = selectors.getBetAmount(state);
    let betIndex = selectors.getBetIndex(state);
    let currency = selectors.getCurrencyInfo(state).currency;
    let balance = selectors.getBalance(state);
    switch (this.gameState) {
      case eGameState.EGS_READY_TO_PLAY:
        if(balance < betAmount && !window.OPWrapperService.freeBetsController.isActive
          && !window.OPWrapperService.freeBetsController.isFirstFreeBet) {
          OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
          return;
        }
        if (window.OPWrapperService.freeBetsController.isActive
          || window.OPWrapperService.freeBetsController.isFirstFreeBet) {
          window.OPWrapperService.freeBetsController.decreaseFreeBets();
          window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
        } else {
          window.OPWrapperService.ControllerStatistic.increaseSpinCount();
        }
        OPWrapperService.realityCheck.blockedRealityCheck = true;

        await requestNewGame(betIndex, currency)
          .then(() => {
            OPWrapperService.ControllerStatistic.bet = betAmount;
          })
          .catch(() => {
            //hide preloader
          });
        break;
      case eGameState.EGS_CAN_CANCEL:
        // no cancel here
        //hide preloader
        break;
      case eGameState.EGS_CAN_TAKE:
        requestTakeGame();
        break;
      default:
        console.log('Unknown state', this.gameState);
        break;
    }
  };

  onGameStateChanged = (value) => {
    this.gameState = value;

    switch (this.gameState) {
      case eGameState.EGS_CAN_CANCEL:
        this.button.disable();
        this.longLabel.style = {
          ...this.longLabel.style,
          fill: ['#ffffff'],
        };
        this.longLabel.text = this.LocalizationManager.getLocalizedText('game_choose_cell');
        this.longLabel.fitSize();
        this.button.text.visible = false;
        this.longLabel.visible = true;
        break;
      case eGameState.EGS_CAN_TAKE:
        this.button.enable();
        this.longLabel.style = {
          ...this.longLabel.style,
          fill: this.button.text.style.fill,
        };
        this.button.text.visible = false;
        this.longLabel.visible = true;
        let state = store.getState();
        let winAmount = selectors.getWinAmount(state);
        this.onWinAmountChanged(winAmount);
        break;
      case eGameState.EGS_READY_TO_PLAY:
        this.button.enable();
        this.button.text.visible = true;
        this.longLabel.visible = false;
        this.button.setText(this.LocalizationManager.getLocalizedText('game_play'));
        break;
      default:
        console.warn(`Unknown gameState: ${this.gameState}`);
        break;
    }
  };

  onWinAmountChanged = (value) => {
    if (this.gameState !== eGameState.EGS_CAN_TAKE) return;
    let state = store.getState();
    let winAmount = value;
    let currencyInfo = selectors.getCurrencyInfo(state);
    let currencyType = selectors.getCurrencyType(state);
    this.longLabel.text = this.LocalizationManager.getLocalizedText('game_take', [formatCurrency(winAmount, currencyInfo.decimal), currencyType]);
    this.longLabel.fitSize();
  }
}
