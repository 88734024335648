import ControllerPopup from '../base/controllerPopup';
import eModalType from './eModalType';
import { store } from '../../index';
import selectors from '../../redux/game/selectors';
import { greenStroke, greenStrokeShadow, yellowStrokeGradient } from '../../fontStyles';

export default class ControllerPopupBigWin extends ControllerPopup {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_WIN_BIG;
    this.initListener();

    this.labelTitle = this.getChildByName('textTitle');
    this.labelTitle.style = {
      ...this.labelTitle.style,
      ...yellowStrokeGradient,
      letterSpacing: 0
    };

    this.labelAmount = this.getChildByName('textAmount');
    this.labelAmount.style = { ...this.labelAmount.style, ...greenStrokeShadow };

    this.labelCoef = this.getChildByName('textCoef');
    this.labelCoef.style = { ...this.labelCoef.style, ...greenStroke };

    this.buttonCollect = this.getChildByName('buttonCollect');
    this.buttonCollect.getChildByName('labelLong').visible = false;
    this.buttonCollect.addListener('pointertap', this.onCloseClicked);

    let buttonCollectLabel = this.buttonCollect.getChildByName('label');
    buttonCollectLabel.text = 'Collect';
    buttonCollectLabel.style = { ...buttonCollectLabel.style, ...yellowStrokeGradient };

    this.initBlocker();
  }

  onModalDataChanged = (data) => {
    this.visible = data.visible;

    if (data.visible) {
      let state = store.getState();
      let lastWin = selectors.getLastWin(state);
      let currencyType = selectors.getCurrencyType(state);
      let currencyInfo = selectors.getCurrencyInfo(state);
      this.labelCoef.text = '×' + lastWin.win_coef;
      this.labelAmount.text = `${lastWin && lastWin.win_amount.toFixed(currencyInfo.decimal)} ${currencyType}`;
    }
  };
}
