export const getUrlParams = (url) => {
  const hashes = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  hashes.map(hash => {
    const [key, val] = hash.split('=');
    if (!key) return;
    params[key] = decodeURIComponent(val)
  });
  return params;
};
