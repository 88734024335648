import { TextInput } from 'pixi-textinput-v5';
import selectors from '../redux/game/selectors';
import { storeObserver } from '../index';
import { store } from '../index';
import actions from '../redux/game/actions';
import { eGameState } from '../redux/game/reducer';
import { formatCurrency } from '../utils/currency';

export default class ControllerBetSelector {

  constructor(main) {
    let field = main.getChildByName('field');
    this.labelBet = field.getChildByName('labelBet');
    this.labelBetCurrency = field.getChildByName('labelBetCurrency');

    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.currencyInfoChanged);
    const betAmount = storeObserver.addListenerAndGetValue(selectors.getBetAmount, this.onBetAmountChanged);
    this.onBetAmountChanged(betAmount);

    this.buttonMin = field.getChildByName('buttonMin');
    this.buttonMin.addListener('pointertap', this.onMinClicked);
    this.buttonMinus = field.getChildByName('buttonMinus');
    this.buttonMinus.addListener('pointertap', this.onMinusClicked);
    this.buttonPlus = field.getChildByName('buttonPlus');
    this.buttonPlus.addListener('pointertap', this.onPlusClicked);
    this.buttonMax = field.getChildByName('buttonMax');
    this.buttonMax.addListener('pointertap', this.onMaxClicked);

    this.dispatch = store.dispatch;

    const gameState = storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.onGameStateChanged(gameState);
    this.labelBetCurrency.text = this.currencyInfo.currency;
  }

  setButtonsEnable = (value) => {
    if (value) {
      this.buttonMin.enable();
      this.buttonMinus.enable();
      this.buttonPlus.enable();
      this.buttonMax.enable();
    } else {
      this.buttonMin.disable();
      this.buttonMinus.disable();
      this.buttonPlus.disable();
      this.buttonMax.disable();
    }
  };

  onGameStateChanged = (gameState) => {
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  onBetAmountChanged = (value) => {
    this.labelBet.text = formatCurrency(value, this.currencyInfo.decimal);
  };

  currencyInfoChanged = (value) => {
    this.currencyInfo = value;
  };

  onMinClicked = () => {
    this.dispatch(actions.setMinBet());
  };

  onMaxClicked = () => {
    this.dispatch(actions.setMaxBet());
  };

  onPlusClicked = () => {
    this.dispatch(actions.incrementBet());
  };

  onMinusClicked = () => {
    this.dispatch(actions.decrementBet());
  };

  // let intervalPlusPressed = useRef(null);
  onPlusPressed = () => {
    intervalPlusPressed.current = setInterval(() => dispatch(actions.incrementBet()), 100);
  };

  onPlusUnpressed = () => {
    clearInterval(intervalPlusPressed.current);
  };

  // let intervalMinusPressed = useRef(null);
  onMinusPressed = () => {
    intervalMinusPressed.current = setInterval(() => dispatch(actions.decrementBet()), 100);
  };

  onMinusUnpressed = () => {
    clearInterval(intervalMinusPressed.current);
  };


  createInput = function createInput() {
    const input = new TextInput({
      input: {
        fontFamily: 'Ubuntu bold',
        fontSize: '36px',
        // textAlign: 'center',
        padding: '12px',
        width: '20px',
        color: '#ffffff',
      },
      box: { fill: 0x000000 }
    });
    console.log(this.labelBet);

    input.x = this.labelBet.x + input.width / 2 + 130;
    input.y = this.labelBet.y + 10;
    input.pivot.x = input.width / 2;
    input.pivot.y = input.height / 2;
    input.text = '123123';
    input.children[3].style = {
      ...input.children[3].style,
      align: 'center'
    };
    // input.children[3].anchor.set(-1, 0);
    input.restrict = '1234567890';
    input.on('blur', () => {
      input.children[3].style = {
        ...input.children[3].style,
        align: 'center'
      };

      input.width = '200px';
      console.log(input.children[3].style);
    })

    field.addChild(input);
  }
}
