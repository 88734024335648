// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!./clover-style.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/UBUNTU-B.TTF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/UBUNTU-R.TTF");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/PHOSPHATE_PRO_SOLID.TTF");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/Inter/Inter-ExtraBold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/Inter/Inter-ExtraBold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/Inter/Inter-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/images/preloader_background.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, "html {\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  background: #000000;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  color: #000;\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  overflow: hidden;\n  background: #000000;\n}\n\n#container, #gameCanvas {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: 0;\n  padding: 0;\n  overflow: visible;\n  width: 100%;\n  min-height: 100%;\n}\n\n#gameCanvas {\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n@font-face {\n  font-family: \"Ubuntu bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Ubuntu\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Phosphate Pro Solid\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  font-display: block;\n}\n@font-face {\n  font-family: \"Inter Extra Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n  font-display: block;\n}\ninput[type=number]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.preloader {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n}", ""]);
// Exports
module.exports = exports;
