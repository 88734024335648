import { handleActions } from 'redux-actions';

import actions from './actions';
import getRandomNumbers from '../../utils/math';
import { getUrlParams } from '../../utils/url';

export const eGameState = {
  EGS_READY_TO_PLAY: 'ready_to_play',
  EGS_CAN_CANCEL: 'can_cancel',
  EGS_CAN_TAKE: 'can_take',
};

export const eCellState = {
  ECS_CLOVER: 'clover',
  ECS_BOMB: 'bomb',
};

let betAmountStep = 0.00000001;
const initialState = {
  betAmount: 1,
  bets: [],
  betsAvailable: [],
  freeBetsAvailable: [],
  rulesCoefs: [2, 5, 15, 118],
  recover: [{ index: 3, value: 1 }],
  currency: 'RUB',
  round_hash: '',
  steps: 1,
  path: [],
  fieldInfo: [
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false },
    { state: eCellState.ECS_CLOVER, isClosed: true, isPath: false }
  ],
  // user state
  balance: 0.5,
  gameState: eGameState.EGS_READY_TO_PLAY,
  ticketId: '000000001',
  betMin: 1,
  betMax: 100,
  statistic: {
    stat_games: 0,
    stat_bets: 0,
    stat_won: 0,
    stat_profit: 0,
  },
  activity: {
    highlights: [],
    myBets: [],
  },
  currencyInfo: {
    symbol: '₽',
    decimal: 2,
    currency: 'rub',
    balance: 0,
  },
  provablyFair: {
    result: [],
    secretSalt: '',
    prevRoundHash: '',
    currentRoundHash: ''
  }
};

const getCellStateFor = (value) => {
  return value !== 1 ? eCellState.ECS_BOMB : eCellState.ECS_CLOVER;
};

const setData = (state, { payload }) => {
  if (payload.is_game) {
    state.gameState = eGameState.EGS_CAN_CANCEL;
  }

  if (payload.recover && payload.recover.length > 0 && payload.is_game) {
    state.gameState = eGameState.EGS_CAN_TAKE;
    for (let item of payload.recover) {
      state.fieldInfo[item.index].state = getCellStateFor(item.value);
      state.fieldInfo[item.index].isClosed = false;
      state.fieldInfo[item.index].isPath = true;
      state.fieldInfo[item.index].coef = payload.coefs[payload.path.indexOf(item.index)];
    }
  }

  const betsAvailable = payload.bets_available;
  const pid = getUrlParams(window.location.search).pid;
  const defaultBetIndex = pid === '217' ? 0 : betsAvailable.length - 2; //217 - PM Belarus
  let currentBetIndex = typeof(payload.bet_index) === 'number' ? payload.bet_index : defaultBetIndex;
  let betAmount = betsAvailable[currentBetIndex];
  let bets = betsAvailable;

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }
  if (payload.decimal) {
    console.warn('decimal field will deprecated soon in config request');
  }

  return {
    ...state,
    betAmount,
    currentBetIndex,
    bets,
    freeBetsAvailable,
    betsAvailable,
    rulesCoefs: payload.coefs,
    recover: payload.recover,
    currency: payload.currency,
    balance: payload.balance || 0,
    statistic: payload.stat,
    steps: payload.steps,
    ticketId: payload.ticketId,
    betMin: bets[0],
    betMax: bets[bets.length - 1],
    currencyInfo: {
      symbol: payload.currency,
      //todo: remove decimal in next release
      decimal: payload.decimal || payload.decimals,
      currency: payload.currency,
      balance: payload.balance,
    }
  };
};

const setNewGame = (state, { payload }) => {
  state.gameState = eGameState.EGS_CAN_CANCEL;

  for (let i in state.fieldInfo) {
    state.fieldInfo[i].isClosed = true;
    // state.fieldInfo[i].isPath = false;
  }

  state = {
    ...state,
    ..._getBetsData(state, payload),
    rulesCoefs: payload.payout,
    statistic: payload.stat,
    ticketId: payload.ticketId,
    balance: payload.balance,
    provablyFair: {
      ...state.provablyFair,
      prevRoundHash: state.provablyFair.currentRoundHash,
      currentRoundHash: payload.round_hash,
    }
  };

  return state;
};

const setDataFieldOpenWin = (state, { payload }) => {
  state.gameState = eGameState.EGS_CAN_TAKE;

  //todo: mutate state is not good, think how to improve
  for (let index of payload.path) {
    state.fieldInfo[index] = {
      ...state.fieldInfo[index],
      state: eCellState.ECS_CLOVER,
      isClosed: false,
      isPath: true,
      coef: state.rulesCoefs[payload.path.indexOf(index)]
    };
  }

  //todo: parse other params

  return {
    ...state,
    ..._getBetsData(state, payload),
    statistic: payload.stat,
    steps: payload.steps
  };
};

const setDataGameEnd = (state, { payload }) => {
  state.gameState = eGameState.EGS_READY_TO_PLAY;

  //todo: mutate state is not good, think how to improve
  for (let index in payload.field) {
    state.fieldInfo[index] = {
      ...state.fieldInfo[index],
      state: getCellStateFor(payload.field[index]),
      isClosed: !(payload.is_win || payload.path.includes(parseFloat(index)) || payload.path.length === 0), //its ok, index is String
      isPath: payload.path.includes(parseFloat(index))
    }
  }

  //todo: parse other params

  const myBetInfo = {
    bet_amount: state.betAmount,
    win_coef: payload.is_win ? state.rulesCoefs[payload.steps - 1] : 0,
    steps: payload.steps,
    is_win: payload.is_win,
    win_amount: payload.is_win ? state.betAmount * state.rulesCoefs[payload.steps - 1] : 0
  };

  state.activity.myBets.unshift(myBetInfo);

  if (state.activity.myBets.length > 20) {
    state.activity.myBets.pop();
  }

  return {
    ...state,
    ..._getBetsData(state, payload),
    balance: payload.balance,
    statistic: payload.stat,
    provablyFair: {
      ...state.provablyFair,
      result: payload.field,
      secretSalt: payload.secret_salt,
    }
  };
};

const openOtherCells = (state) => {
  if (state.gameState !== eGameState.EGS_READY_TO_PLAY) return state;

  const result = state.fieldInfo.map((item) => ({
    ...item,
    isClosed: false,
    coef: item.isClosed ? 0 : item.coef
  }));

  return { ...state, fieldInfo: result };
};


const incrementBet = (state) => {
  const step = state.currentBetIndex + 1;
  if (step >= state.bets.length) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  }
};

const decrementBet = (state) => {
  const step = state.currentBetIndex - 1;
  if (step < 0) {
    return state;
  }
  let newAmount = state.bets[step];

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex: step,
  }
};

const setMinBet = (state) => {
  return {
    ...state,
    betAmount: state.betMin,
    currentBetIndex: 0,
  }
};

const setMaxBet = (state) => {
  let newAmount = state.betMax;
  let currentBetIndex = state.bets.length - 1;

  return {
    ...state,
    betAmount: newAmount,
    currentBetIndex
  }
};

const initHighlights = (state, { payload }) => {
  let result = [];
  for (let item of payload.objects.reverse()) {
    item.nickname = item.nickname || `player#${getRandomNumbers(4)}`;
    result.push(item);
  }

  return { ...state, activity: { ...state.activity, highlights: result } };
};

const addHighlight = (state, { payload }) => {
  payload.nickname = payload.nickname || `player#${getRandomNumbers(4)}`;
  state.activity.highlights.unshift(payload);

  if (state.activity.highlights.length > 20) {
    state.activity.highlights.pop();
  }

  return {
    ...state,
    activity: {
      ...state.activity,
      highlights: state.activity.highlights
    }
  };
};

const setBetAmount = (state, { payload }) => {
  let newBetAmount = payload;
  if (!newBetAmount) return state;

  newBetAmount = newBetAmount > state.balance ? state.balance : newBetAmount;
  newBetAmount = newBetAmount < betAmountStep ? betAmountStep : newBetAmount;

  return { ...state, betAmount: newBetAmount };
};

const setCurrencyInfo = (state, { payload }) => {
  //todo: ask Vova to send balance with floating point
  return ({
    ...state,
    currency: payload.currency.toUpperCase(),
    balance: payload.balance / Math.pow(10, payload.decimal),
    currencyInfo: payload
  })
};

const _getBetsData = (state, payload) => {
  let bets = state.betsAvailable;

  let betAmount = payload.bet_amount ? payload.bet_amount : state.betAmount;

  let currentBetIndex = state.betsAvailable.indexOf(betAmount)

  //freeBets
  let freeBetsAvailable = [];
  if (payload.freebets) {
    freeBetsAvailable = payload.freebets.bet_levels;
    const freeBetsIsActive = payload.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;

    if (freeBetsIsActive) {
      currentBetIndex = payload.freebets.bet_level;
      betAmount = freeBetsAvailable[currentBetIndex];
      bets = freeBetsAvailable;
    }
  }
  return {
    bets,
    betAmount,
    currentBetIndex,
    freeBetsAvailable
  }
}

export default handleActions(
  {
    [actions.setData]: setData,
    [actions.setNewGame]: setNewGame,
    [actions.setDataFieldOpenWin]: setDataFieldOpenWin,
    [actions.setDataGameEnd]: setDataGameEnd,
    [actions.incrementBet]: incrementBet,
    [actions.decrementBet]: decrementBet,
    [actions.setMinBet]: setMinBet,
    [actions.setMaxBet]: setMaxBet,
    [actions.setBetAmount]: setBetAmount,
    [actions.initHighlights]: initHighlights,
    [actions.addHighlight]: addHighlight,
    [actions.openOtherCells]: openOtherCells,
    [actions.setCurrencyInfo]: setCurrencyInfo,
  },
  initialState,
);
