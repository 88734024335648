import ControllerPopup from '../base/controllerPopup';
import eModalType from './eModalType';

export default class ControllerPopupRules extends ControllerPopup {

  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_RULES;
    this.initListener();

    this.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);
    this.initBlocker();
  }
}
