import eModalType from './eModalType';
import ControllerPopupHTML from '../base/controllerPopupHTML';

export default class Controller extends ControllerPopupHTML {
  constructor() {
    super();
    this.type = eModalType.EMT_RULES;
    this.element = document.getElementById(this.type);
    this.initListener();
  }

  onModalDataChanged = (data) => {
    const value = data.visible ? 'block' : 'none';
    this.element.style.setProperty('display', value);

    const LocalizationManager = window.OPWrapperService.localizations;
    const title = this.element.getElementsByClassName('pop-up__title')[0];
    title.innerHTML = LocalizationManager.getLocalizedText('rules_title');

    const text_0 = this.element.getElementsByClassName('pop-up__rules-text')[0].children[0];
    text_0.innerHTML = LocalizationManager.getLocalizedText('rules_text_0');

    const text_1 = this.element.getElementsByClassName('pop-up__rules-text')[0].children[1];
    text_1.innerHTML = LocalizationManager.getLocalizedText('rules_text_1');
  };
}
