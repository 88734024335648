import ControllerSounds from '../controllerSounds';

export default class ControllerButtonSound {
  constructor(container) {
    const button = container.getChildByName('buttonSound');
    this.buttonOn = button.getChildByName('buttonOn');
    this.buttonOn.on('pointertap', this.onButtonOnClicked);
    this.buttonOff = button.getChildByName('buttonOff');
    this.buttonOff.on('pointertap', this.onButtonOffClicked);
    this.buttonOff.visible = false;

    ControllerSounds.onSoundSettingsChanged.add(this.onSoundSettingsChanged.bind(this))
  }

  onButtonOnClicked = () => {
    this.buttonOn.visible = false;
    this.buttonOff.visible = true;
    if (ControllerSounds.SFXPlaying) {
      ControllerSounds.muteSFX();
    }
  };

  onButtonOffClicked = () => {
    this.buttonOn.visible = true;
    this.buttonOff.visible = false;
    if (!ControllerSounds.SFXPlaying) {
      ControllerSounds.unmuteSFX();
    }
  };

  onSoundSettingsChanged() {
    const state = ControllerSounds.muted;
    this.buttonOff.visible = state;
    this.buttonOn.visible = !state;
  }
}
