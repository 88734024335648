export const generateLabelMainButtonYellow = (container) => {
  const text = new PIXI.BitmapText('PLAY', {
    font: {
      name: 'BmFontPhosphate',
      size: 110,
    },
    align: 'center',
  });
  text.anchor.set(0.5);
  text.letterSpacing = 5;
  text.position.x = container.children[0].width / 2 - 5;
  text.position.y = container.children[0].height / 2 - 5;
  container.addChild(text);

  return text;
};

export const generateLabelMainButtonWhite = (container) => {
  const text = new PIXI.BitmapText('CHOOSE ANY CELL', {
    font: {
      name: 'BmFontPhosphate_white',
      size: 75,
    },
    align: 'center',
  });
  text.anchor.set(0.5);
  text.position.x = container.children[0].width / 2 - 5;
  text.position.y = container.children[0].height / 2 - 5;
  container.addChild(text);

  return text;
};
