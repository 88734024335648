import ControllerPopupHTML from '../base/controllerPopupHTML';
import eModalType from './eModalType';
import { store } from '../../index';
import selectors from '../../redux/game/selectors';

export default class Controller extends ControllerPopupHTML {
  constructor() {
    super();
    this.type = eModalType.EMT_WIN_BIG;
    this.element = document.getElementById(this.type);
    this.initListener();


    this.titleBg = this.element.getElementsByClassName('pop-up__big-win-title-bg')[0];
    this.title = this.element.getElementsByClassName('pop-up__big-win-title-light')[0].children[0];

    this.labelCoef = this.element.getElementsByClassName('x-sum')[0];
    this.labelAmount = this.element.getElementsByClassName('sum')[0];
    this.labelCurrency = this.element.getElementsByClassName('currency')[0];
  }

  onModalDataChanged = (data) => {
    const LocalizationManager = window.OPWrapperService.localizations;
    const value = data.visible ? 'block' : 'none';
    this.element.style.setProperty('display', value);

    if (data.visible) {
      const titleLabel = LocalizationManager.getLocalizedText('win_title');
      this.title.innerHTML = titleLabel;
      this.titleBg.setAttribute('data-text', titleLabel);

      const congrats = this.element.getElementsByClassName('pop-up__inner')[0].children[2];
      congrats.innerHTML = LocalizationManager.getLocalizedText('win_congratulations');

      const lucky = this.element.getElementsByClassName('pop-up__inner')[0].children[4];
      lucky.innerHTML = LocalizationManager.getLocalizedText('win_you_are_lucky');

      const buttonCollect = this.element.getElementsByClassName('big-win-btn__bg')[0];
      buttonCollect.setAttribute('data-text', LocalizationManager.getLocalizedText('win_collect'));

      let state = store.getState();
      let lastWin = selectors.getLastWin(state);
      let currencyType = selectors.getCurrencyType(state);
      let currencyInfo = selectors.getCurrencyInfo(state);
      this.labelCoef.innerHTML = '×' + lastWin.win_coef;
      this.labelAmount.innerHTML = `${lastWin && lastWin.win_amount.toFixed(currencyInfo.decimal)}`;
      this.labelCurrency.innerHTML = ` ${currencyType}`;
    }
  };
  onCloseClicked() {
    super.onCloseClicked();
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
}
