import Event from '../utils/event';
export const eSoundType = {
  EST_BIG_WIN: 'bigWin',
  EST_CLICK: 'click',
  EST_COINS: 'coins',
  EST_LOSE: 'lose',
  EST_OPEN: 'open',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
};

class ControllerSounds {

  constructor() {
    this.assetsList = [
      { id: eSoundType.EST_BIG_WIN, src: 'assets/sounds/bigWin.mp3' },
      { id: eSoundType.EST_CLICK, src: 'assets/sounds/click.mp3' },
      { id: eSoundType.EST_COINS, src: 'assets/sounds/coins.mp3' },
      { id: eSoundType.EST_LOSE, src: 'assets/sounds/lose.mp3' },
      { id: eSoundType.EST_OPEN, src: 'assets/sounds/open.mp3' },
      { id: eSoundType.EST_SFX, src: 'assets/sounds/sfx.mp3', loop: true },
      { id: eSoundType.EST_WIN, src: 'assets/sounds/win.mp3' },
    ];

    this.sounds = {};
    this.volume = 0.1;
    this.SFXPlaying = false;
    this.muted = false;

    this.onSoundSettingsChanged = new Event();

    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  startLoading(loadingComplete) {
    this.loadingComplete = loadingComplete;
    for (const item of this.assetsList) {
      const audioElement = new Audio(item.src);
      audioElement.loop = item.loop;
      audioElement.volume = this.volume;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        audioElement.preload = 'auto';//this is hack for iOS shit
        this.onSoundLoaded(item);
      } else {
        audioElement.addEventListener('loadeddata', (event) => {
          this.onSoundLoaded(item);
        });
      }
      this.sounds[item.id] = audioElement;
    }
  }

  onSoundLoaded = (data) => {
    this.assetsList.map((item) => {
      if (item.id === data.id) {
        item.loaded = true;
      }
    });

    this.checkLoadingComplete();
  };

  onVisibilityChange = () => {
    if (this.muted) return;
    if (document.hidden) {
      this.pauseSFX();
    } else {
      this.playSFX();
    }
  };

  checkLoadingComplete = () => {
    let loadingComplete = false;
    for (let item of this.assetsList) {
      if (item.loaded) {
        loadingComplete = true;
      } else {
        loadingComplete = false;
        break;
      }
    }

    if (loadingComplete) {
      this.loadingComplete();
    }
  };

  playSound(type) {
    if(this.muted) return;
    this.sounds[type].currentTime = 0;
    return this.sounds[type].play();
  }

  muteSounds() {
    for (let key in this.sounds) {
      this.sounds[key].volume = 0;
    }
    this.muted = true;
    this.onSoundSettingsChanged.call();
  }

  unmuteSounds() {
    for (let key in this.sounds) {
      this.sounds[key].volume = this.volume;
    }
    this.muted = false;
    this.onSoundSettingsChanged.call();
  }

  muteSFX() {
    this.muted = true;
    this.pauseSFX();
  }

  unmuteSFX() {
    this.muted = false;
    this.playSFX();
  }

  playSFX = () => {
    if (this.SFXPlaying || this.muted) return;

    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
    this.playSound(eSoundType.EST_SFX)
      .then(() => {
        this.SFXPlaying = true;
        this.onSoundSettingsChanged.call();
      })
      .catch(() => {
        document.addEventListener('mousedown', this.playSFX);
        document.addEventListener('touchstart', this.playSFX);
      });
  };

  pauseSFX() {
    this.SFXPlaying = false;
    this.onSoundSettingsChanged.call();
    this.sounds[eSoundType.EST_SFX].pause();
    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
  }
}

export default new ControllerSounds();
