import { yellowStrokeGradient } from '../fontStyles';
import ControllerSounds from './controllerSounds';
import { generateLabelMainButtonYellow } from '../factories/labels';

export default class ControllerStartScreen {
  constructor(container) {
    const LocalizationManager = window.OPWrapperService.localizations;
    this.container = container;
    this.container.interactive = true;
    this.label = container.getChildByName('welcome_message');
    this.label.style = {
      ...this.label.style,
      ...yellowStrokeGradient,
      letterSpacing: 1,
      fontSize: LocalizationManager.currentLanguage === 'en' ? 60 : 50,
    };
    this.label.fitSize();

    this.buttonStart = container.getChildByName('buttonStart');
    this.buttonStart.getChildByName('labelLong').visible = false;
    const buttonLabel = this.buttonStart.getChildByName('label');
    buttonLabel.text = LocalizationManager.getLocalizedText('welcome_start');
    buttonLabel.style = {
      ...buttonLabel.style,
      ...yellowStrokeGradient,
      letterSpacing: 1,
    };

    // const labelStart = generateLabelMainButtonYellow(this.buttonStart);
    // labelStart.text = 'START';
    // console.log(this.label)

    this.buttonStart.on('pointertap', this.onButtonStartClicked);
  }

  onButtonStartClicked = () => {
    this.container.visible = false;
    ControllerSounds.playSFX();
    window.OPWrapperService.Fullscreen.showButtonIfAndroid()
    window.OPWrapperService.freeBetsController.show();
  };
}
