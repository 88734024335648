import FlashLib from 'flashlib';
import ControllerButton from './controllers/base/controllerButton';
import ControllerFieldCell from './controllers/controllerFieldCell';
import ControllerPopupRules from './controllers/popups/popupRules';
import ControllerPopupWin from './controllers/popups/popupWin';
import ControllerPopupBigWin from './controllers/popups/popupBigWin';

FlashLib.registerClass('Button', ControllerButton);
FlashLib.registerClass('FieldCell', ControllerFieldCell);
FlashLib.registerClass('ControllerPopupRules', ControllerPopupRules);
FlashLib.registerClass('ControllerPopupBigWin', ControllerPopupBigWin);
FlashLib.registerClass('ControllerPopupWin', ControllerPopupWin);
