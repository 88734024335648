import LocalizeTextField from './LocalizeTextField';

export default class ControllerTextField extends LocalizeTextField {
  constructor(...args) {
    super(...args);
    this.style.fontFamily = [this.style.fontFamily, 'Inter Extra Bold'];
    this._positionArguments = [];
  }

  fitSize() {
    super.fitSize(true, true);
  }

  correctPosition = (...args) => {
    if (args.length) {
      this._positionArguments = args;
    }

    super.correctPosition(...this._positionArguments);
  };
}
