import { handleActions } from 'redux-actions';

import userActions from './actions';
import eModalType from '../../controllers/popups/eModalType';

const getInitialState = () => {
  const result = {};

  for (let key in eModalType) {
    result[eModalType[key]] = {
      visible: false
    }
  }

  return result;
};

export default handleActions(
  {
    [userActions.showModal]: (state, { payload }) => {
      console.log('Show popup: ', payload.type);

      return {
        ...state,
        [payload.type]: {
          visible: true
        }
      };
    },
    [userActions.closeModal]: (state, { payload }) => {
      console.log('Hide popup: ', payload.type);

      return {
        ...state,
        [payload.type]: {
          visible: false
        }
      };
    }
  },
  getInitialState()
);
